import { ClockIcon, PriceTagIcon, PaymentIcon, UserCheckIcon, CopyIcon, ShareIcon } from "src/assets/svg";
import { BreadCrumbs, Modal, ParticipantCard } from "src/components";
import { InfoCard } from "../FellowshipDetails";
import { useState } from "react";
import { InviteJudge } from "src/modals";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { judgeFellowshipDetails } from "src/api";
import { copyToClipboard, formatStringDate, generateUrl, renderCurrency, renderSuccessMessage } from "src/utilities/functions";
import sanitizeHtml from "sanitize-html";
import { sanitizeHtmlOption } from "src/utilities/constants";

const FellowshipDetails = () => {
	const { id } = useParams();
	const [isInviteJudgeOpen, setIsInviteJudgeOpen] = useState(false);

	const { data } = useQuery({
		queryKey: ["creator-fellowship", id],
		queryFn: () => judgeFellowshipDetails(id),
		suspense: true,
	});

	const fellowship = data?.data?.fellowship;
	const applicants = data?.data?.applicants;

	const url = generateUrl(`/programs/${fellowship?.uuid}`);

	return (
		<>
			<BreadCrumbs />
			<h1 className="dashboard-header">{fellowship?.title}</h1>
			<p className="text-bodyText2">Program type: {fellowship?.type}</p>
			<div className="grid min-[950px]:grid-cols-[1fr_max-content] gap-16 pt-20 pb-24">
				<div>
					<h2 className="text-[1.4rem] text-[#0485BC] uppercase font-medium mb-8">DETAILS</h2>
					<div className="grid gap-8 grid-cols-[repeat(auto-fit,minmax(155px,1fr))] pb-12">
						<InfoCard
							text={formatStringDate(fellowship?.closing_date || "2024-06-28")}
							icon={<ClockIcon />}
							information="Application Closes"
						/>
						<InfoCard text={renderCurrency(fellowship?.project_budget)} icon={<PriceTagIcon />} information="Budget" />
						<InfoCard text={fellowship?.payment_frequency?.replace("-", " ")} icon={<PaymentIcon />} information="Payment Frequency" />
						<InfoCard text={fellowship?.level_of_expertise} icon={<UserCheckIcon />} information="Expertise" />
					</div>
					<div className="pb-12">
						<div className="">
							<h2 className="text-[1.4rem] text-[#0485BC] uppercase font-medium mb-8">DESCRIPTION</h2>
							<div
								className="*:text-[1.6rem] *:text-justify *:leading-[1.55] *:text-bodyText *:mb-10"
								dangerouslySetInnerHTML={{
									__html: sanitizeHtml(fellowship?.description, sanitizeHtmlOption),
								}}
							/>
						</div>
					</div>
					<div className="pb-24">
						<h2 className="text-[1.4rem] text-[#0485BC] uppercase font-medium mb-14">Skills and expertise</h2>
						<div className="flex gap-6 max-w-[550px] flex-wrap">
							{fellowship?.skills_required?.map((skill, index) => (
								<p key={index} className="text-[1.4rem] rounded-full font-medium text-bodyText px-6 py-4 bg-[#EFEFEF]">
									{skill}
								</p>
							))}
						</div>
					</div>
					<div>
						<h2 className="text-[1.4rem] text-[#0485BC] uppercase font-medium mb-14">Program link</h2>
						<p className="text-[1.4rem] mb-6 font-medium text-bodyText bg-[#EFEFEF] rounded-full px-6 py-4 w-fit">{url}</p>
						<div className="flex items-center gap-6">
							<button
								className="text-bodyText flex items-center gap-3 text-[1.6rem] font-semibold"
								onClick={() => copyToClipboard(url).then(() => renderSuccessMessage("URL copied"))}
							>
								<CopyIcon className="text-bodyText" /> Copy Link
							</button>
							<button className="text-bodyText flex items-center gap-3 text-[1.6rem] font-semibold">
								<ShareIcon className="text-bodyText" /> Share
							</button>
						</div>
					</div>
				</div>
				<div className="min-[950px]:w-[clamp(270px,40vw,350px)] grid gap-10 max-[950px]:grid-cols-[repeat(auto-fit,minmax(280px,1fr))] h-fit">
					<ParticipantStack title="Applicants" users={applicants}>
						<Link to="applicants" className="py-4 mb-5 rounded-lg primary-btn">
							View All
						</Link>
					</ParticipantStack>
				</div>
			</div>
			<Modal isOpen={isInviteJudgeOpen} setIsOpen={setIsInviteJudgeOpen} title="Invite Judge">
				<InviteJudge selectedFellowship={{ id: 10 }} />
			</Modal>
		</>
	);
};

const ParticipantStack = ({ title, users, children }) => {
	return (
		<div className="grid divide-y divide-[#EAEBF0] px-8 border border-[#EAEBF0] rounded-lg">
			<p className="text-[1.6rem] text-[#272D37] font-medium py-5 flex justify-between items-center">{title}</p>
			{!!users?.length ? (
				users?.map((user, index) => <ParticipantCard user={user} key={index} />)
			) : (
				<div className="text-center text-bodyText text-[1.6rem] py-8">No one yet</div>
			)}
			{children}
		</div>
	);
};

export default FellowshipDetails;
