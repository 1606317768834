import React, { Suspense } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import SideBar from "./SideBar";
import TopNav from "./TopNav";
import styled from "styled-components";
import { Loader } from "src/components";
import { useAppContext } from "src/context/AppContext";

const Container = styled.div`
	display: flex;
	overflow: hidden;

	.wrapper {
		max-height: 100vh;
		min-height: 100vh;
		flex: 1;
		width: calc(100% - 240px);
		background-color: #ffffff;
		overflow-y: scroll;

		& > div {
			padding-left: 4rem;
			padding-right: 4rem;
		}
	}

	@media only screen and (max-width: 769px) {
		.wrapper {
			min-height: 100vh;
			flex: 1;
			width: 100%;

			& > div {
				padding-left: 2rem;
				padding-right: 2rem;
			}

			.main {
				margin-top: 2rem;
			}
		}
	}
`;

const MainLayout = () => {
	const { pathname, search } = useLocation();
	const { user, token } = useAppContext();

	if (!user || !token) return <Navigate to={{ pathname: "/login", search: `redirect=${encodeURIComponent(pathname.concat(search))}` }} />;

	return (
		<Container>
			<SideBar />
			<div className="wrapper">
				<TopNav />
				<div className="main">
					<Suspense fallback={<Loader />}>
						<Outlet />
					</Suspense>
				</div>
			</div>
		</Container>
	);
};

export default MainLayout;
