import React, { useState } from "react";
import Slider from "react-slick";
import { RightArrowIcon, DownIcon, CheckIcon, CheckListIcon, ReceiptIcon, CheckCircleIcon, CardIcon } from "src/assets/svg";
import Carousel1 from "src/assets/carousel-1.png";
import Carousel2 from "src/assets/carousel-2.png";
import Carousel3 from "src/assets/carousel-3.png";
import Carousel4 from "src/assets/carousel-4.png";
import Carousel5 from "src/assets/carousel-5.png";
import Carousel6 from "src/assets/carousel-6.png";
import Tab1Image from "src/assets/tab1.png";
import Tab2Image from "src/assets/tab2.png";
import Tab3Image from "src/assets/tab3.png";
import Tab4Image from "src/assets/tab4.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "react-responsive";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { FeaturedCard, Modal } from "src/components";
import Popup from "reactjs-popup";
import { CreateFellowship } from "src/modals";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "src/context/AppContext";
import { useQuery } from "@tanstack/react-query";
import { getFellowships } from "src/api";
import { Skeleton } from "src/components/ui/skeleton";

const carouselSettings = {
	infinite: true,
	speed: 4000,
	autoplaySpeed: 0,
	autoplay: true,
	slidesToShow: 3,
	slidesToScroll: 1,
	arrows: false,
	className: "min-[800px]:max-h-[650px] min-[800px]:w-fit w-full",
};
const rtlCarouselSettings = {
	...carouselSettings,
	rtl: true,
};

const Landing = () => {
	const { fellowshipTypes, user, token } = useAppContext();
	const navigate = useNavigate();
	const shouldBeVertical = useMediaQuery({ query: "(min-width: 800px)" });

	const [selectedType, setSelectedType] = useState(fellowshipTypes?.at(0));
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

	const { data: rawFeaturedFellowship, isLoading } = useQuery({
		queryKey: ["featured-fellowships", selectedType?.uuid],
		queryFn: () => getFellowships({ type: selectedType?.uuid, per_page: 6 }),
	});

	const openModal = () => (!user || !token ? navigate("/login") : setIsCreateModalOpen(true));

	const featuredFellowship = rawFeaturedFellowship?.data?.data;

	return (
		<>
			<div className="[--bleed-color:#F1F4F6] bg-[var(--bleed-color)] full-bleed max-[800px]:!px-0">
				<div className="grid min-[800px]:grid-cols-2 min-h-[500px] max-[800px]:py-24">
					<div className="my-auto max-[800px]:px-12">
						<h1 className="text-headerText mb-8 leading-[115%] font-Roobert min-[800px]:leading-[7.5rem] text-[4.4rem] min-[800px]:text-[6.4rem] font-bold max-w-[700px]">
							One platform to help you manage your programs
						</h1>
						<p className="max-w-[700px] mb-8 text-bodyText text-[1.65rem] font-medium">
							Streamline program management with our platform—simplify applications, boost collaboration, and enhance engagement while
							saving time and resources.
						</p>
						<div className="flex flex-wrap items-center gap-8 mb-8">
							<button className="primary-btn" onClick={openModal}>
								Create Program <RightArrowIcon />
							</button>
							<Link to="/contact" className="secondary-btn">
								Contact us
							</Link>
						</div>
					</div>
					<div className="flex max-[800px]:flex-col justify-center gap-10 w-full overflow-auto max-[800px]:-px-[6rem]">
						<Slider {...{ ...carouselSettings, vertical: shouldBeVertical }}>
							<div style={{ width: "auto" }}>
								<img src={Carousel1} alt="" />
							</div>
							<div style={{ width: "auto" }}>
								<img src={Carousel2} alt="" />
							</div>
							<div style={{ width: "auto" }}>
								<img src={Carousel3} alt="" />
							</div>
							<div style={{ width: "auto" }}>
								<img src={Carousel1} alt="" />
							</div>
							<div style={{ width: "auto" }}>
								<img src={Carousel2} alt="" />
							</div>
							<div style={{ width: "auto" }}>
								<img src={Carousel3} alt="" />
							</div>
						</Slider>
						<Slider {...{ ...rtlCarouselSettings, vertical: shouldBeVertical }}>
							<div style={{ width: "auto" }}>
								<img src={Carousel4} alt="" />
							</div>
							<div style={{ width: "auto" }}>
								<img src={Carousel5} alt="" />
							</div>
							<div style={{ width: "auto" }}>
								<img src={Carousel6} alt="" />
							</div>
							<div style={{ width: "auto" }}>
								<img src={Carousel4} alt="" />
							</div>
							<div style={{ width: "auto" }}>
								<img src={Carousel5} alt="" />
							</div>
							<div style={{ width: "auto" }}>
								<img src={Carousel6} alt="" />
							</div>
						</Slider>
					</div>
				</div>
			</div>
			<div className="py-28">
				<h2 className="text-headerText text-[3.6rem] leading-[115%] font-semibold font-Roobert text-center mb-10">The four steps</h2>
				{/* <p className="text-bodyText text-[1.6rem] text-center max-w-[550px] mx-auto mb-10">
					Co-Hub aims to be a vibrant online platform where individuals and organisations can come together to collaborate for
					opportunities.
				</p> */}
				<Tabs selectedTabClassName="text-white bg-primary" className="grid">
					<TabList className="flex mx-auto max-w-full overflow-scroll hide-scrollbar bg-[#F2F2F2] p-3 rounded-full text-headerText font-medium font-Roobert text-[1.6rem] gap-3">
						<Tab className="px-[2rem] md:px-[3rem] py-[1rem] rounded-full hover:cursor-pointer hover:bg-primary hover:text-white transition-all">
							Application
						</Tab>
						<Tab className="px-[2rem] md:px-[3rem] py-[1rem] rounded-full hover:cursor-pointer hover:bg-primary hover:text-white transition-all">
							Onboarding
						</Tab>
						<Tab className="px-[2rem] md:px-[3rem] py-[1rem] rounded-full hover:cursor-pointer hover:bg-primary hover:text-white transition-all">
							Execution
						</Tab>
						<Tab className="px-[2rem] md:px-[3rem] py-[1rem] rounded-full hover:cursor-pointer hover:bg-primary hover:text-white transition-all">
							Completion
						</Tab>
					</TabList>
					<TabPanel className="pt-28 react-tabs__tab-panel">
						<TabContent
							header="Simplify Fellowship Management with Kohort"
							text="Kohort offers powerful tools to design and publicize your fellowship, manage applications, streamline candidate review, and coordinate interviews—ensuring a smooth recruitment process from start to finish."
							image={Tab1Image}
						/>
					</TabPanel>
					<TabPanel className="pt-28 react-tabs__tab-panel">
						<TabContent
							header="Streamline Fellow Onboarding with Kohort"
							text="Kohort enhances the onboarding experience by guiding fellows through orientation, aligning goals, providing essential resources, and supporting mentorship—ensuring they are well-prepared and supported throughout your program."
							image={Tab2Image}
						/>
					</TabPanel>
					<TabPanel className="pt-28 react-tabs__tab-panel">
						<TabContent
							header="Optimize Fellowship Execution with Kohort"
							text="Kohort streamlines task execution, real-time monitoring, and feedback during fellowships. The platform ensures ongoing support through mentorship and resources, while allowing easy adjustments to keep your program aligned with its goals."
							image={Tab3Image}
						/>
					</TabPanel>
					<TabPanel className="pt-28 react-tabs__tab-panel">
						<TabContent
							header="Simplify Fellowship Completion with Kohort"
							text="Kohort guides fellows in submitting final deliverables, facilitates comprehensive evaluations, and gathers feedback through exit interviews. It also integrates graduates into the alumni network, ensuring ongoing engagement and long-term connections."
							image={Tab4Image}
						/>
					</TabPanel>
				</Tabs>
			</div>
			<div className="pb-28">
				<div className="flex flex-wrap items-center justify-between mb-10">
					<h2 className="text-headerText font-semibold font-Roobert text-[3rem] md:text-[3.2rem] flex items-center gap-3">
						Latest{" "}
						<Popup
							trigger={
								<button className="flex text-[3rem] items-center gap-1 capitalize text-primary">
									{selectedType?.name} <DownIcon className="w-10 h-10" />
								</button>
							}
							position="bottom center"
							closeOnDocumentClick
						>
							<div className="flex flex-col gap-2 py-2 w-[150px]">
								{fellowshipTypes?.map((type, index) => (
									<button
										className={[
											"px-8 py-3 flex gap-3 items-center text-left capitalize hover:bg-gray-200 font-medium font-Roobert text-headerText",
											selectedType?.uuid === type?.uuid && "!text-primary",
										].join(" ")}
										onClick={() => setSelectedType(type)}
										key={index}
									>
										{type.name} {selectedType?.uuid === type?.uuid && <CheckIcon />}
									</button>
								))}
							</div>
						</Popup>
					</h2>
					<Link
						className="flex items-center text-[1.8rem] font-semibold gap-3 text-primary"
						to={{ pathname: "/programs", search: `?type=${selectedType?.uuid}` }}
					>
						View All <RightArrowIcon />
					</Link>
				</div>
				<div className="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] lg:grid-cols-[repeat(auto-fill,minmax(400px,1fr))] gap-12 min-h-[20vh]">
					{isLoading
						? Array(4)
								.fill("")
								.map((_, index) => <SkeletonCard key={index} />)
						: featuredFellowship?.map((fellowship, index) => <FeaturedCard fellowship={fellowship} key={index} />)}
				</div>
			</div>
			<div className="py-28">
				<h2 className="text-headerText text-[3.6rem] leading-[115%] font-semibold font-Roobert text-center mb-6 text-balance max-w-[550px] mx-auto">
					Get more hands and minds working along with your team!
				</h2>
				<p className="text-bodyText text-[1.6rem] text-center max-w-[625px] mx-auto mb-24">
					Kohort will streamline and make it easy for you to manage fellowships, conference scholarships, or internships. From application,
					onboarding and execution, Kohort makes sure that you get the best out of your talent investment.
					<br /> Use Kohort to manage:
				</p>
				<div className="grid gap-x-8 gap-y-24 grid-cols-[repeat(auto-fit,minmax(300px,1fr))]">
					<ImpactCard
						text="Kohort streamlines fellowship management from start to finish. The platform supports the entire process, from initial announcements through candidate selection, program execution, and long-term alumni engagement. "
						title="Fellowships"
						icon={<CheckListIcon className="mb-12 text-headerText" />}
					/>
					<ImpactCard
						text="Kohort streamlines conference scholarship management. Offer scholarships, receive applications, make selections, and track scholars through to alumni status—all on one platform. Simplify your process from start to finish"
						title="Conference Scholarships"
						icon={<ReceiptIcon className="mb-12 text-headerText" />}
					/>
					<ImpactCard
						text="Kohort simplifies internship programs. Post openings, review applications, select candidates, and manage interns —all in one place. Streamline your internship process from recruitment to long-term engagement and possible employment. ."
						title="Internships"
						icon={<CheckCircleIcon className="mb-12 stroke-[1.5px] text-headerText" />}
					/>
					<ImpactCard
						text="Kohort helps you facilitate pitch competitions, receive applications, judge applications, and select winners. "
						title="Startup Pitch Competitions "
						className="last-impact"
						icon={<CardIcon className="mb-12 text-headerText" />}
					/>
				</div>
			</div>
			<Modal isOpen={isCreateModalOpen} setIsOpen={setIsCreateModalOpen} title="Create a fellowship">
				<CreateFellowship />
			</Modal>
		</>
	);
};

const ImpactCard = ({ icon, title, text, className }) => {
	return (
		<div className={className}>
			{icon}
			<div>
				<p className="text-headerText font-Roobert mb-4 font-semibold text-[1.8rem]">{title}</p>
				<p className="text-bodyText text-[1.45rem]">{text}</p>
			</div>
		</div>
	);
};

const TabContent = ({ header, text, image }) => {
	return (
		<div className="grid gap-8 sm:grid-cols-2">
			<div className="grid place-items-center">
				<div>
					<h3 className="text-headerText font-Roobert font-semibold text-[2.8rem] max-w-[400px] leading-[115%] mb-10">{header}</h3>
					<p className="text-[1.45rem] text-bodyText max-w-[500px]">{text}</p>
				</div>
			</div>
			<div>
				<img src={image} alt="tab-display" />
			</div>
		</div>
	);
};

const SkeletonCard = () => {
	return (
		<div className="flex flex-col space-y-3">
			<Skeleton className="h-[125px] rounded-xl" />
			<div className="space-y-2">
				<Skeleton className="h-6 w-[250px]" />
				<Skeleton className="h-6 w-[200px]" />
			</div>
		</div>
	);
};

export default Landing;
