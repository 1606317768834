import { ErrorMessage, useFormikContext } from "formik";
import React from "react";
import Popup from "reactjs-popup";
import { CheckIcon, DownIcon } from "src/assets/svg";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";

const PopupSelect = ({ options, name, placeholder, value }) => {
	const { setFieldValue } = useFormikContext();

	return (
		<div>
			<Select onValueChange={(value) => setFieldValue(name, value)} defaultValue={value}>
				<SelectTrigger className={!!value ? "text-bodyText" : "text-[#a8b0bd]"}>
					<SelectValue placeholder={placeholder} />
				</SelectTrigger>
				<SelectContent>
					{options?.map(({ text, value }, index) => (
						<SelectItem key={index} value={value} className="font-medium font-Roobert text-bodyText hover:text-primary">
							{text}
						</SelectItem>
					))}
				</SelectContent>
			</Select>
			{!!name && <ErrorMessage name={name} component="div" className="block mt-2 text-[1.2rem] text-red-500" />}
		</div>
	);
};

export const PopupSelect2 = ({ value, options, display, name, placeholder, buttonClassName, popupClassName }) => {
	return (
		<div>
			<Popup
				trigger={
					<button
						type="button"
						className={`flex border border-[#919BAF4D] px-8 py-4 rounded-lg items-center justify-between w-full gap-1 capitalize text-bodyText ${buttonClassName}`}
					>
						{!!value ? display : placeholder} <DownIcon className="w-[20px] h-[20px] text-bodyText" />
					</button>
				}
				position="bottom left"
				closeOnDocumentClick
			>
				<div className={`flex flex-col gap-2 py-2 w-[300px] ${popupClassName}`}>
					{options.map(({ onClick, text, value: optionValue }, index) => {
						const isSelected = value === optionValue;
						return (
							<button
								className={[
									"px-10 py-5 w-full flex gap-3 items-center text-left capitalize hover:bg-gray-100 hover:text-primary font-medium font-Roobert text-bodyText",
									isSelected && "!text-primary",
								].join(" ")}
								onClick={onClick}
								key={index}
							>
								{text} {isSelected && <CheckIcon />}
							</button>
						);
					})}
				</div>
			</Popup>
			{!!name && <ErrorMessage name={name} component="div" className="block mt-2 text-[1.2rem] text-red-500" />}
		</div>
	);
};

export default PopupSelect;
