import { useQuery } from "@tanstack/react-query";
import React, { Suspense, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { getApplicantFellowships } from "src/api";
import { ClockIcon, NoFellowshipIcon } from "src/assets/svg";
import { EmptyState, Loader, Modal, Pagination } from "src/components";
import { useAppContext } from "src/context/AppContext";
import { sanitizeHtmlOption } from "src/utilities/constants";
import avatar from "src/assets/avatar.png";
import sanitizeHtml from "sanitize-html";
import { formatStringDate } from "src/utilities/functions";
import { SubmitProposal } from "src/modals";

const MyFellowships = () => {
	const { pathname, search } = useLocation();
	const { user, token } = useAppContext();

	const [status, setStatus] = useState("all");

	if (!user || !token) return <Navigate to={{ pathname: "/login", search: `redirect=${encodeURIComponent(pathname.concat(search))}` }} />;

	return (
		<div id="fellowship-page">
			<div className="[--bleed-color:#F1F4F6] bg-[var(--bleed-color)] full-bleed grid gap-10 py-6 md:py-20">
				<h1 className="font-Roobert font-bold text-[2.4rem] md:text-[4rem] text-headerText">My Programs</h1>
			</div>
			<div className="pt-10 pb-24">
				<ul className="flex gap-6 text-bodyText2 mb-14 border-b border-[#EAEBF0] w-fit">
					<li className={`pb-2 px-2 ${status === "all" && "border-b-2 !font-medium border-primary text-primary"}`}>
						<button className="flex items-center gap-2" onClick={() => setStatus("all")}>
							All
							{/* <span
								className={`px-[6px] rounded-full font-medium bg-[#EFEFF1] text-[1.3rem] ${
									status === "all" && "!text-white !bg-primary"
								}`}
							>
								4
							</span> */}
						</button>
					</li>
					<li className={`pb-2 px-2 ${status === "pending" && "border-b-2 !font-medium border-primary text-primary"}`}>
						<button className="flex items-center gap-2" onClick={() => setStatus("pending")}>
							Pending
							{/* <span
								className={`px-[6px] rounded-full font-medium bg-[#EFEFF1] text-[1.3rem] ${
									status === "pending" && "!text-white !bg-primary"
								}`}
							>
								4
							</span> */}
						</button>
					</li>
					<li className={`pb-2 px-2 ${status === "accepted" && "border-b-2 !font-medium border-primary text-primary"}`}>
						<button className="flex items-center gap-2" onClick={() => setStatus("accepted")}>
							Selected
							{/* <span
								className={`px-[6px] rounded-full font-medium bg-[#EFEFF1] text-[1.3rem] ${
									status === "accepted" && "!text-white !bg-primary"
								}`}
							>
								4
							</span> */}
						</button>
					</li>
					<li className={`pb-2 px-2 ${status === "engaged" && "border-b-2 !font-medium border-primary text-primary"}`}>
						<button className="flex items-center gap-2" onClick={() => setStatus("engaged")}>
							Engaged
							{/* <span
								className={`px-[6px] rounded-full font-medium bg-[#EFEFF1] text-[1.3rem] ${
									status === "engaged" && "!text-white !bg-primary"
								}`}
							>
								4
							</span> */}
						</button>
					</li>
				</ul>
				<Suspense fallback={<Loader />}>
					<RenderArea status={status} />
				</Suspense>
			</div>
		</div>
	);
};

const RenderArea = ({ status }) => {
	const { data } = useQuery({
		queryKey: ["user-fellowships", status],
		queryFn: () => getApplicantFellowships({ per_page: 15, page: 1, status }),
		suspense: true,
	});

	const fellowships = data?.data?.fellowships?.data;
	const count = data?.data?.fellowships?.total;

	if (!fellowships?.length)
		return (
			<EmptyState icon={<NoFellowshipIcon />}>
				<p className="font-Roobert font-medium text-[1.6rem] md:text-[2rem] text-center text-bodyText2">
					You have not joined any programs yet.
					<br />
					Please click the button below to view programs you can join
				</p>
				<Link to="/programs" className="secondary-btn w-fit text-[1.4rem] py-5">
					View Programs
				</Link>
			</EmptyState>
		);

	return (
		<>
			<div className="grid gap-x-8 gap-y-11 grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
				{fellowships?.map((fellowship, index) => (
					<FellowshipCard fellowship={fellowship} key={index} />
				))}
			</div>
			<Pagination rowCount={count} />
		</>
	);
};

const FellowshipCard = ({ fellowship }) => {
	const { pathname } = useLocation();

	const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);

	const prefix = pathname.includes("creator") ? "/creator/my-programs" : pathname.includes("judge") ? "/judge" : "/programs";

	const usersArray = Array(fellowship?.total_users).fill("");
	const overflowCount = usersArray.length - 4;

	return (
		<>
			<Link to={prefix.concat(`/${fellowship?.uuid}`)} className="px-6 py-6 border rounded-lg grid border-[#eaeaea] relative">
				<p className="font-Roobert font-semibold text-[1.8rem] mb-3">{fellowship?.title}</p>
				<div
					className="*:text-[1.4rem] *:text-bodyText *:font-medium mb-5 leading-[1.5] truncate-overflow [--max-lines:3] [--line-height:2.1rem]"
					dangerouslySetInnerHTML={{
						__html: sanitizeHtml(fellowship?.description, sanitizeHtmlOption),
					}}
				/>
				<div className="flex items-center justify-between mb-5">
					{!!usersArray?.length && (
						<div className="flex items-center">
							{usersArray.slice(0, 4).map((image, index) => (
								<img
									key={index}
									src={avatar}
									alt=""
									className={["w-[35px] aspect-square rounded-full border border-gray-400", index === 0 ? "" : "-ml-5"].join(" ")}
								/>
							))}
							{overflowCount > 0 && (
								<div className="w-[35px] aspect-square rounded-full -ml-5 bg-headerText text-white flex items-center justify-center font-semibold text-[1.4rem]">
									+{overflowCount}
								</div>
							)}
						</div>
					)}
					{!!fellowship?.status ? (
						<span
							className={`text-[1.2rem] font-medium ${
								fellowship?.status === "Pending" ? "bg-[#FFF8EB] text-[#EEA23E]" : "bg-[#F0FAF0] text-[#2D8A39]"
							} px-3 py-2 rounded-full`}
						>
							{fellowship?.status}
						</span>
					) : (
						<p className="flex items-center gap-2 text-[1.4rem] font-medium text-[#0485BC]">
							<ClockIcon /> {formatStringDate(fellowship?.closing_date || "2024-06-24")}
						</p>
					)}
				</div>

				{fellowship?.status === "Pending" ? (
					<div className="block w-full p-3 text-center border rounded-full font-semibold text-[1.4rem] text-bodyText2 border-[#EAEBF0]">
						View Program
					</div>
				) : fellowship?.status === "Selected" ? (
					<button
						onClick={(event) => {
							event.stopPropagation();
							event.preventDefault();
							setIsSubmitModalOpen(true);
						}}
						className="block w-full z-50 p-3 text-center border rounded-full font-semibold text-[1.4rem] text-bodyText2 border-[#EAEBF0]"
					>
						Send Proposal
					</button>
				) : (
					<div className="block w-full z-50 p-3 text-center border rounded-full font-semibold text-[1.4rem] text-bodyText2 border-[#EAEBF0]">
						View Contract
					</div>
				)}
			</Link>
			<Modal isOpen={isSubmitModalOpen} setIsOpen={setIsSubmitModalOpen} title="Submit Proposal" size="l">
				<SubmitProposal fellowship={fellowship} />
			</Modal>
		</>
	);
};

export default MyFellowships;
