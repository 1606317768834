import React from "react";
import Popup from "reactjs-popup";

const CustomPopup = ({ trigger, options, ...rest }) => {
	return (
		<Popup trigger={trigger} position="bottom left" closeOnDocumentClick {...rest}>
			<div className="flex flex-col gap-2 py-2 min-w-[150px]">
				{options.map(({ onClick, text }, index) => (
					<button
						className="flex items-center w-full gap-3 px-10 py-5 font-medium text-left capitalize hover:bg-gray-100 hover:text-primary font-Roobert text-bodyText2"
						onClick={onClick}
						key={index}
					>
						{text}
					</button>
				))}
			</div>
		</Popup>
	);
};

export default CustomPopup;
