import { ErrorMessage } from "formik";
import React from "react";

const CustomTextArea = ({ label, containerClassName, className, name, children, ...rest }) => {
	return (
		<div className={`grid gap-1 ${containerClassName}`}>
			<label htmlFor={label} className="mb-3 block font-medium text-[1.6rem] text-bodyText2">
				{label}
			</label>
			<textarea
				id={label}
				rows="5"
				className={`border rounded-md border-lightGray focus:![--tw-ring-shadow:transparent] placeholder:text-bodyText focus:border-primary px-8 py-4 text-[1.6rem] placeholder:text-[1.6rem] text-bodyText ${className}`}
				name={name}
				{...rest}
			></textarea>
			<div className="flex items-center">
				{!!name && <ErrorMessage name={name} component="div" className="text-red-500 text-[1rem]" />}
				{children}
			</div>
		</div>
	);
};

export default CustomTextArea;
