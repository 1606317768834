import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { Suspense } from "react";
import { getNotifications, markNotificationAsRead } from "src/api";
import { CloseIcon } from "src/assets/svg";
import { Loader, Pagination } from "src/components";
import { formatStringDate, renderSuccessMessage } from "src/utilities/functions";

const Notifications = () => {
	return (
		<div>
			<div className="[--bleed-color:#F1F4F6] bg-[var(--bleed-color)] full-bleed grid gap-10 py-6 md:py-20">
				<h1 className="font-Roobert font-bold text-[2.4rem] md:text-[4rem] text-headerText">Notifications</h1>
			</div>
			<Suspense fallback={<Loader />}>
				<RenderArea />
			</Suspense>
		</div>
	);
};

const RenderArea = () => {
	const { data } = useQuery({
		queryKey: ["notifications", false],
		queryFn: () => getNotifications(false),
		suspense: true,
	});

	const notifications = data?.data?.data;
	const count = data?.data?.total;

	return (
		<div className="pt-10 pb-20">
			<div className="grid gap-3">
				{notifications?.map((notification, index) => (
					<Notification notification={notification} key={index} />
				))}
			</div>
			<Pagination rowCount={count} />
		</div>
	);
};

const Notification = ({ notification }) => {
	const queryClient = useQueryClient();
	const { mutate } = useMutation(markNotificationAsRead, {
		onSuccess: async ({ message }) => {
			await queryClient.refetchQueries({ queryKey: ["notifications", false] });
			renderSuccessMessage(message);
		},
	});

	return (
		<div className="flex items-start justify-between gap-5 py-8 rounded-xl">
			<div>
				<p className="max-w-[1000px] text-[1.75rem] text-bodyText2 mb-2">{notification?.message}</p>
				<p className="text-headerText text-[1.6rem] font-semibold">{formatStringDate(notification?.created_at ?? "2024-07-06", "MMM dd")}</p>
			</div>
			{!notification?.is_read && (
				<button onClick={() => mutate({ notification: notification.uuid })} className="p-2 rounded-full hover:bg-gray-300">
					<CloseIcon className="w-[22px] h-[22px]" />
				</button>
			)}
		</div>
	);
};

export default Notifications;
