import React, { Suspense, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
	AddIcon,
	DocumentIcon,
	EditIcon,
	EngageIcon,
	MessagesIcon,
	NoUserIcon,
	RightArrowHeadIcon,
	UserCancelIcon,
	UserCheckIcon,
} from "src/assets/svg";
import { BreadCrumbs, CustomDataTable, EmptyState, Loader, Modal } from "src/components";
import { format } from "date-fns";
import { ApplicantDetails, ApplicantProposals, AssignToJudge, EngageApplicant, RateApplicant } from "src/modals";
import { useParams, useSearchParams } from "react-router-dom";
import { useMutation, useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	creatorFellowshipDetails,
	fellowshipApplicants,
	fellowshipEngagedApplicants,
	fellowshipGradedApplicants,
	fellowshipSelectedApplicants,
	selectApplicant,
	unSelectApplicant,
} from "src/api";
import { renderConfirmDialogue, renderCurrency, renderSuccessMessage } from "src/utilities/functions";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { EditEngageApplicant } from "src/modals/EngageApplicant";
import ViewMilestoneSubmission from "src/modals/ViewMilestoneSubmission";

const tabs = { applicants: 0, graded: 1, selected: 2, engaged: 3 };
const engagedStatusClassGenerator = (status) =>
	status === "pending" ? "text-[#EEA23E] bg-[#FFF8EB]" : status === "accepted" ? "bg-[#F0FAF0] text-[#2D8A39]" : "bg-red-200 text-red-500";

const FellowshipApplicants = () => {
	const { id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const { data } = useQuery({
		queryKey: ["creator-fellowship", id],
		queryFn: () => creatorFellowshipDetails(id),
		suspense: true,
	});

	const [isAssignToJudgeOpen, setIsAssignToJudgeOpen] = useState(false);
	const [applicantRows, setApplicantRows] = useState([]);
	const [gradedRows, setGradedRows] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);

	const fellowship = data?.data?.fellowship;

	return (
		<div>
			<BreadCrumbs />
			<div className="flex flex-wrap items-center justify-between mb-10">
				<h1 className="dashboard-header">Applicants</h1>
				<RenderActionButtons {...{ applicantRows, gradedRows, selectedRows, setIsAssignToJudgeOpen }} />
			</div>
			<Suspense fallback={<Loader />}>
				<Tabs
					selectedTabClassName="text-primary border-b border-primary"
					defaultIndex={searchParams.has("tab") ? tabs[searchParams.get("tab")] : 0}
					onSelect={(_, __, event) => setSearchParams({ tab: event.target.dataset.id })}
				>
					<TabList className="flex gap-10 mb-14">
						<Tab data-id="applicants" className="pb-4 cursor-pointer font-medium text-[1.5rem] text-bodyText">
							Applicants
						</Tab>
						<Tab data-id="graded" className="pb-4 cursor-pointer font-medium text-[1.5rem] text-bodyText">
							Graded
						</Tab>
						<Tab data-id="selected" className="pb-4 cursor-pointer font-medium text-[1.5rem] text-bodyText">
							Selected
						</Tab>
						<Tab data-id="engaged" className="pb-4 cursor-pointer font-medium text-[1.5rem] text-bodyText">
							Engaged
						</Tab>
					</TabList>
					<TabPanel>
						<Applicants setSelectedRows={setApplicantRows} />
					</TabPanel>
					<TabPanel>
						<Graded setSelectedRows={setGradedRows} />
					</TabPanel>
					<TabPanel>
						<Selected setSelectedRows={setSelectedRows} />
					</TabPanel>
					<TabPanel>
						<Engaged />
					</TabPanel>
				</Tabs>
			</Suspense>
			<Modal isOpen={isAssignToJudgeOpen} setIsOpen={setIsAssignToJudgeOpen} title="Assign to a judge" size="sm">
				<AssignToJudge applicants={applicantRows} fellowship={fellowship} />
			</Modal>
		</div>
	);
};

const RenderActionButtons = ({ setIsAssignToJudgeOpen, applicantRows, gradedRows, selectedRows }) => {
	const { id } = useParams();
	const [engageModalOpen, setEngageModalOpen] = useState(false);
	const [searchParams] = useSearchParams();
	const queryClient = useQueryClient();

	const { mutate: selectMutate, isLoading: selectLoading } = useMutation(selectApplicant, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(async () => {
				await queryClient.invalidateQueries({ queryKey: ["fellowship-graded-applicants"], type: "active" });
			}),
	});

	const { mutate: unSelectMutate, isLoading: unSelectLoading } = useMutation(unSelectApplicant, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(async () => {
				await queryClient.invalidateQueries({ queryKey: ["fellowship-selected-applicants"], type: "active" });
			}),
	});

	const currentTab = searchParams.get("tab") || "applicants";

	return (
		<>
			{currentTab === "applicants"
				? !!applicantRows.length && (
						<button className="px-6 py-4 primary-btn" onClick={() => setIsAssignToJudgeOpen(true)}>
							<AddIcon /> Assign to a judge
						</button>
				  )
				: currentTab === "graded"
				? !!gradedRows.length && (
						<button
							className="py-6 primary-btn"
							onClick={() =>
								renderConfirmDialogue("Are you sure?", "These applicants will be selected for the fellowship").then((result) => {
									if (result) selectMutate({ fellowship: id, applicants: gradedRows?.map((applicant) => applicant.id) });
								})
							}
							disabled={selectLoading}
						>
							<UserCheckIcon className="w-[20px] h-[20px] stroke-[1.5px]" />
							Select Applicants
						</button>
				  )
				: currentTab === "selected"
				? !!selectedRows.length && (
						<div className="flex items-center gap-5">
							<button
								className="py-6 primary-btn"
								onClick={() =>
									renderConfirmDialogue("Are you sure?", "These applicants will be unselected for the fellowship").then(
										(result) => {
											if (result)
												unSelectMutate({ fellowship: id, applicants: selectedRows?.map((applicant) => applicant.id) });
										}
									)
								}
								disabled={unSelectLoading}
							>
								<UserCancelIcon className="w-[20px] h-[20px] stroke-[1.5px]" />
								Unselect Applicants
							</button>
							<button className="py-6 primary-btn" onClick={() => setEngageModalOpen(true)}>
								<EngageIcon className="w-[20px] h-[20px] stroke-[1.5px]" />
								Engage Applicants
							</button>
						</div>
				  )
				: null}
			<Modal isOpen={engageModalOpen} size="l" setIsOpen={setEngageModalOpen} title={`Engage Applicants`}>
				<EngageApplicant fellowship={id} applicants={selectedRows} />
			</Modal>
		</>
	);
};

const Applicants = ({ setSelectedRows }) => {
	const { id } = useParams();

	const { data } = useQuery({
		queryKey: ["fellowship-applicants", id],
		queryFn: () => fellowshipApplicants(id),
		suspense: true,
	});
	const columns = [
		{
			name: "Name & Email",
			cell: (row) => (
				<div className="overflow-hidden">
					<p data-tag="allowRowEvents" className="text-headerText">
						{row?.first_name} {row?.last_name}
					</p>
					<p data-tag="allowRowEvents" className="mt-1 lowercase truncate text-bodyText">
						{row?.email}
					</p>
				</div>
			),
		},
		{
			name: "Phone Number",
			selector: (row) => row?.phone_number,
		},
		{
			name: "Date Applied",
			selector: (row) => format(row?.date_applied || "2024-04-25", "dd MMMM yyyy"),
		},
		{
			name: "Judges Assigned",
			selector: (row) => row.judges_assigned,
		},
		{
			name: "Program",
			selector: (row) => row?.fellowship_name,
			style: { color: "var(--header-text)" },
		},
	];

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [row, setRow] = useState(null);
	const handleRowChange = ({ selectedRows }) => setSelectedRows(selectedRows);

	const applicants = data?.data?.data;

	return (
		<>
			<CustomDataTable
				columns={columns}
				data={applicants}
				selectableRows
				onSelectedRowsChange={handleRowChange}
				highlightOnHover
				pointerOnHover
				onRowClicked={(row) => {
					setRow(row);
					setIsModalOpen(true);
				}}
				noDataComponent={
					<EmptyState icon={<NoUserIcon />}>
						<p className="font-Roobert font-medium text-[1.6rem] text-center text-bodyText2">This fellowship has no applicants</p>
					</EmptyState>
				}
			/>
			<Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="Applicant Details" size="l">
				<ApplicantDetails application={row} />
			</Modal>
		</>
	);
};

const Graded = ({ setSelectedRows }) => {
	const { id } = useParams();

	const { data } = useQuery({
		queryKey: ["fellowship-graded-applicants", id],
		queryFn: () => fellowshipGradedApplicants(id),
		suspense: true,
	});
	const columns = [
		{
			name: "Name",
			selector: (row) => `${row?.first_name} ${row?.last_name}`,
		},
		{
			name: "Email",
			selector: (row) => row?.email,
		},
		{
			name: "Judges Assigned",
			selector: (row) => row.judges_assigned,
		},
		{
			name: "Grade Average",
			selector: (row) => row.average_grade,
		},
		{
			name: "Program",
			selector: (row) => row?.fellowship_name,
			style: { color: "var(--header-text)" },
		},
		{
			name: "",
			cell: (row) => <GradedActionButtons applicant={row} />,
			width: "200px",
		},
	];

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [row, setRow] = useState(null);
	const handleRowChange = ({ selectedRows }) => setSelectedRows(selectedRows);

	const applicants = data?.data?.data;

	return (
		<>
			<CustomDataTable
				columns={columns}
				data={applicants}
				selectableRows
				onSelectedRowsChange={handleRowChange}
				highlightOnHover
				pointerOnHover
				onRowClicked={(row) => {
					setRow(row);
					setIsModalOpen(true);
				}}
				noDataComponent={
					<EmptyState icon={<NoUserIcon />}>
						<p className="font-Roobert font-medium text-[1.6rem] text-center text-bodyText2">This fellowship has no graded applicants</p>
					</EmptyState>
				}
			/>
			<Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="Applicant Details" size="l">
				<ApplicantDetails application={row} />
			</Modal>
		</>
	);
};

const GradedActionButtons = ({ applicant }) => {
	const { id } = useParams();
	const queryClient = useQueryClient();

	const { mutate, isLoading } = useMutation(selectApplicant, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(async () => {
				await queryClient.invalidateQueries({ queryKey: ["fellowship-graded-applicants"], type: "active" });
			}),
	});

	return (
		<button
			className="flex items-center gap-3 text-primary min-w-fit"
			onClick={() =>
				renderConfirmDialogue("Are you sure?", "This applicant will be selected for the fellowship").then((result) => {
					if (result) mutate({ fellowship: id, applicants: [applicant?.id] });
				})
			}
			disabled={isLoading}
		>
			<UserCheckIcon /> Select Applicant
		</button>
	);
};

const Selected = ({ setSelectedRows }) => {
	const { id } = useParams();

	const { data } = useQuery({
		queryKey: ["fellowship-selected-applicants", id],
		queryFn: () => fellowshipSelectedApplicants(id),
		suspense: true,
	});
	const columns = [
		{
			name: "Name",
			selector: (row) => `${row?.first_name} ${row?.last_name}`,
		},
		{
			name: "Email",
			selector: (row) => row?.email,
		},
		{
			name: "Judges Assigned",
			selector: (row) => row.judges_assigned,
		},
		{
			name: "Grade Average",
			selector: (row) => row.average_grade,
		},
		{
			name: "Program",
			selector: (row) => row?.fellowship_name,
			style: { color: "var(--header-text)" },
		},
		{
			name: "",
			cell: (row) => <SelectedActionButtons applicant={row} />,
			width: "150px",
		},
	];

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [row, setRow] = useState(null);
	const handleRowChange = ({ selectedRows }) => setSelectedRows(selectedRows);

	const applicants = data?.data?.data;

	return (
		<>
			<CustomDataTable
				columns={columns}
				data={applicants}
				selectableRows
				onSelectedRowsChange={handleRowChange}
				highlightOnHover
				pointerOnHover
				onRowClicked={(row) => {
					setRow(row);
					setIsModalOpen(true);
				}}
				noDataComponent={
					<EmptyState icon={<NoUserIcon />}>
						<p className="font-Roobert font-medium text-[1.6rem] text-center text-bodyText2">
							This fellowship has no selected applicants
						</p>
					</EmptyState>
				}
			/>
			<Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="Applicant Details" size="l">
				<ApplicantDetails application={row} />
			</Modal>
		</>
	);
};

const SelectedActionButtons = ({ applicant }) => {
	const { id } = useParams();
	const queryClient = useQueryClient();

	const [setModalOpen, setSetModalOpen] = useState(false);
	const [engageModalOpen, setEngageModalOpen] = useState(false);

	const { mutate, isLoading } = useMutation(unSelectApplicant, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(async () => {
				await queryClient.invalidateQueries({ queryKey: ["fellowship-selected-applicants"], type: "active" });
			}),
	});

	return (
		<>
			<div className="flex items-center gap-4">
				<button
					className="text-primary"
					onClick={() =>
						renderConfirmDialogue("Are you sure?", "This applicant will be unselected for the fellowship").then((result) => {
							if (result) mutate({ fellowship: id, applicants: [applicant?.id] });
						})
					}
					disabled={isLoading}
					title="Unselect Applicant"
				>
					<UserCancelIcon className="w-[20px] h-[20px] stroke-[1.5px]" />
				</button>
				{applicant?.has_submitted_proposal && (
					<button className="text-primary" onClick={() => setSetModalOpen(true)} disabled={isLoading} title="View Proposals">
						<DocumentIcon className="w-[20px] h-[20px] stroke-[2px]" />
					</button>
				)}
				<button className="text-primary" title="Send Message">
					<MessagesIcon className="w-[22px] h-[22px] stroke-[2px]" />
				</button>
				<button className="text-primary" title="Engage Applicant" onClick={() => setEngageModalOpen(true)}>
					<EngageIcon className="w-[22px] h-[22px] stroke-[2px]" />
				</button>
			</div>
			<Modal
				isOpen={setModalOpen}
				size="l"
				setIsOpen={setSetModalOpen}
				title={`Applicant Proposal - ${applicant?.first_name} ${applicant?.last_name}`}
			>
				<ApplicantProposals fellowship={id} applicant={applicant?.uuid} />
			</Modal>
			<Modal
				isOpen={engageModalOpen}
				size="l"
				setIsOpen={setEngageModalOpen}
				title={`Engage Applicant - ${applicant?.first_name} ${applicant?.last_name}`}
			>
				<EngageApplicant fellowship={id} applicants={[applicant]} />
			</Modal>
		</>
	);
};

const Engaged = () => {
	const { id } = useParams();

	const [{ data: rawFellowship }, { data: rawEngagements }] = useQueries({
		queries: [
			{
				queryKey: ["creator-fellowship", id],
				queryFn: () => creatorFellowshipDetails(id),
				suspense: true,
			},
			{
				queryKey: ["fellowship-engaged-applicants", id],
				queryFn: () => fellowshipEngagedApplicants(id),
				suspense: true,
			},
		],
	});

	const fellowship = rawFellowship?.data?.fellowship;
	const engagements = rawEngagements?.data?.data;

	return (
		<Accordion allowMultiple>
			{engagements?.map((engagement, index) => (
				<EngagedApplicant engagement={engagement} key={index} fellowship={fellowship} index={index} />
			))}
		</Accordion>
	);
};

const EngagedApplicant = ({ engagement, index, fellowship }) => {
	const { id } = useParams();
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isRateApplicantModalOpen, setIsRateApplicantModalOpen] = useState(false);

	return (
		<>
			<AccordionItem
				itemKey={index}
				// disabled={engagement?.payment_type === "one-time"}
				key={index}
				className="random"
				headingProps={{
					className: "random",
				}}
				panelProps={{
					className: "random",
				}}
				header={({ state }) => (
					<div className="flex items-center justify-between w-full gap-4">
						<div className="text-left">
							<p className="text-[#001439] text-[1.6rem] font-medium flex items-center gap-4">
								{engagement?.applicant?.first_name} {engagement?.applicant?.last_name}{" "}
								<span
									className={`text-[1.2rem] px-2 py-1 flex items-center justify-center rounded-full ${engagedStatusClassGenerator(
										engagement?.status
									)}`}
								>
									{engagement?.status}
								</span>
							</p>
							<p className="text-[#818B9C] text-[1.4rem]">{engagement?.applicant?.email}</p>
						</div>
						<div className="flex items-center gap-5">
							{engagement?.status !== "accepted" && (
								<button title="Edit engagement" onClick={() => setIsEditModalOpen(true)}>
									<EditIcon className="text-primary" />
								</button>
							)}
							{fellowship?.status === "closed" && (
								<button className="z-10 py-5 secondary-btn px-9" onClick={() => setIsRateApplicantModalOpen(true)}>
									Rate applicant
								</button>
							)}
							{engagement?.payment_type !== "one-time" && (
								<button className="bg-[#FFF7F5] text-primary flex items-center gap-5 px-7 py-4 rounded-full">
									View Progress <RightArrowHeadIcon className={`w-[18px] h-[18px] ${state.isEnter ? "-rotate-90" : "rotate-90"}`} />
								</button>
							)}
						</div>
					</div>
				)}
			>
				<div>
					<p className="font-bold text-[1.8rem] text-headerText mb-2">Milestone Timeline</p>
					<div className="grid gap-5">
						{engagement?.milestones?.map((milestone, index) => (
							<Milestone milestone={milestone} engagement={engagement} index={index} key={index} />
						))}
					</div>
				</div>
			</AccordionItem>
			<Modal
				isOpen={isEditModalOpen}
				size="l"
				setIsOpen={setIsEditModalOpen}
				title={`Edit Engagement Terms - ${engagement?.applicant?.first_name} ${engagement?.applicant?.last_name}`}
			>
				<EditEngageApplicant fellowship={id} engagement={engagement} />
			</Modal>
			<Modal
				isOpen={isRateApplicantModalOpen}
				size="sm"
				setIsOpen={setIsRateApplicantModalOpen}
				title={`Rate applicant - ${engagement?.applicant?.first_name} ${engagement?.applicant?.last_name}`}
			>
				<RateApplicant applicant={engagement?.applicant} fellowship={id} />
			</Modal>
		</>
	);
};

const Milestone = ({ milestone, engagement, index }) => {
	const [isViewModalOpen, setIsViewModalOpen] = useState(false);

	return (
		<>
			<div className="flex timeline relative before:w-[1px] before:h-[calc(100%-30px-10px)] before:bg-[#919BAF66] before:absolute before:top-[calc(35px)] before:left-[14px] gap-6 min-h-[80px]">
				<p className="border text-primary border-primary min-w-[30px] flex items-center justify-center rounded-full text-center h-[30px]">
					{index + 1}
				</p>
				<div className="grid gap-5 pb-14 h-fit">
					<p className={`text-[#101928] text-[1.6rem] ${milestone?.status === "yet to start" && "!text-[#D9DDE4]"}`}>
						{milestone?.description}
					</p>
					<div className="flex items-center gap-6">
						<p className={`text-[#001439] text-[1.8rem] font-semibold ${milestone?.status === "yet to start" && "!text-[#D9DDE4]"}`}>
							{renderCurrency(milestone?.amount)}
						</p>
						<p
							className={`text-[#0485BC] text-[1.4rem] font-Roobert font-medium capitalize ${
								milestone?.status === "yet to start" && "!text-[#D9DDE4]"
							}`}
						>
							{milestone?.status}
						</p>
					</div>
					{milestone?.status === "awaiting confirmation" && (
						<button className="px-4 py-4 primary-btn w-fit" onClick={() => setIsViewModalOpen(true)}>
							View Submission
						</button>
					)}
				</div>
			</div>
			<Modal isOpen={isViewModalOpen} setIsOpen={setIsViewModalOpen} title="View Milestone Submission" size="sm">
				<ViewMilestoneSubmission milestone={milestone} engagement={engagement} />
			</Modal>
		</>
	);
};

export default FellowshipApplicants;
